








import { Component, Vue } from "vue-property-decorator";

import Flash from "@/components/common/Flash.vue";
import Loading from "@/components/common/Loading.vue";
import MainLayout from "@/layout/Main.vue";

@Component({
  components: {
    flash: Flash,
    loading: Loading,
    MainLayout
  }
})
export default class App extends Vue {}
