const package = require("../../package.json");

module.exports = {
  production: {
    API_ENDPOINT: "/api/v1",
    BASE_URL: "/",
    VERSION: package.version
  },
  development: {
    API_ENDPOINT: "/api/v1",
    BASE_URL: "/"
  },
  // eslint-disable-next-line max-len
  emptyImg:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
};
