export default [
  {
    path: "/shop",
    page: "index",
    name: "shop",
    meta: {
      module: "shop",
      title: "marketplace",
      drawer: true,
      main: true
    },
    children: [
      {
        path: "product/:slug",
        page: "display",
        name: "product_display",
        meta: {
          module: "shop",
          title: "product.display"
        }
      }
    ]
  }
];
