import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";

export interface IFlash {
  variant: string;
  message: string;
}

@Module({
  name: "flash",
  dynamic: true,
  store: store
})
export default class Flash extends VuexModule {
  public snack = false;
  public snackColor = "";
  public snackText = "";

  @Mutation
  private SET_FLASH(flash: IFlash): void {
    this.snackColor = flash.variant || "info";
    this.snackText = flash.message;
    this.snack = true;
  }

  @Mutation
  private CLEAR_FLASH(): void {
    this.snack = false;
    this.snackText = this.snackColor = "";
  }

  @Action
  message(message: IFlash): void {
    this.SET_FLASH(message);
  }

  @Action
  success(message: string): void {
    this.message({ message: message, variant: "success" });
  }

  @Action
  error(message: string): void {
    this.message({ message: message, variant: "error" });
  }

  @Action
  warning(message: string): void {
    this.message({ message: message, variant: "warning" });
  }

  @Action
  info(message: string): void {
    this.message({ message: message, variant: "info" });
  }

  @Action
  closeFlash(): void {
    this.CLEAR_FLASH();
  }

  get getSnack(): boolean {
    return this.snack;
  }

  get getSnackColor(): string {
    return this.snackColor;
  }

  get getSnackText(): string {
    return this.snackText;
  }
}

export const FlashModule = getModule(Flash);
