import Vue from "vue";
// import { i18n } from "@/plugins/i18n";
import {
  ValidationProvider,
  ValidationObserver,
  //   configure,
  localize,
  extend
} from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import * as rules from "vee-validate/dist/rules";
import _ from "lodash";

localize("es", es);

for (let [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation
  });
}

// configure({
//   defaultMessage: (field, values) => {
//     values._field_ = i18n.t(`fields.${field}`);
//     return i18n.t(`validations.${values._rule_}`, values);
//   }
// });

// Register it globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
