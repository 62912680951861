import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import _ from "lodash";
import { AuthModule } from "@/store/auth";
import { LayoutModule } from "@/store/layout";
import { FlashModule } from "@/store/flash";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const config = require("@/config");
const ENV = process.env.NODE_ENV;
const { API_ENDPOINT } = ENV ? _.get(config, ENV) : "";

axios.defaults.baseURL = API_ENDPOINT;
_.set(axios.defaults.headers.common, "Access-Control-Allow-Origin", "*");

axios.interceptors.request.use(
  reqConfig => {
    if (!reqConfig || _.isUndefined(reqConfig.url)) {
      return reqConfig;
    }

    const url = reqConfig.baseURL || reqConfig.url;

    if (_.startsWith(url, API_ENDPOINT)) {
      // Add Bearer auth
      if (localStorage.getItem("access_token")) {
        _.set(
          reqConfig.headers.common,
          "Authorization",
          `Bearer ${localStorage.getItem("access_token")}`
        );
      }

      // Add refresh token
      if (reqConfig.url.includes("/auth/logout")) {
        _.set(
          reqConfig.headers,
          "X-REFRESH-TOKEN",
          localStorage.getItem("refresh_token")
        );
      }

      // Add CSRF TOKEN
      _.set(reqConfig.headers, "X-CSRF-TOKEN", AuthModule.csrfToken);
    }

    return reqConfig;
  },
  error => {
    return Promise.reject(error);
  }
);

function forceLogout() {
  AuthModule.logout();
  LayoutModule.setLayout("login");
}

axios.interceptors.response.use(
  reqConfig => {
    return reqConfig;
  },

  err => {
    const data = _.get(err, "response.data");

    if (_.isObject(err.response.config)) {
      const url = _.get(err, "response.config.url");

      if (
        url.includes("/login") ||
        url.includes("/refresh") ||
        url.includes("/invalidate")
      ) {
        if (data) {
          if (_.has(data, "error") || _.has(data, "message")) {
            FlashModule.error(data.error || data.message);
            err = data;
          }
        }

        return Promise.reject(err);
      }
    }

    if (_.indexOf([400, 401], err.response.status) !== -1) {
      forceLogout();
    }

    if (data) {
      if (_.has(data, "error") || _.has(data, "message")) {
        FlashModule.error(data.error || data.message);
        err = data;
      }
    }

    return Promise.reject(err);
  }
);

Vue.use(VueAxios, axios);
