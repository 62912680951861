






import { Component, Vue } from "vue-property-decorator";
import { LoadingModule } from "@/store/loading";

@Component({ name: "loading" })
export default class Loading extends Vue {
  get isLoading(): boolean {
    return LoadingModule.isLoading;
  }
}
