








import { Component, Vue } from "vue-property-decorator";
import AppBar from "@/layout/components/AppBar.vue";
import MainView from "@/layout/components/View.vue";
import MainFooter from "@/layout/components/Footer.vue";
import { AppModule } from "@/store/app";

@Component({
  name: "main-layout",
  components: {
    AppBar,
    MainView,
    MainFooter
  }
})
export default class MainLayout extends Vue {
  public drawer = false;

  async created() {
    await AppModule.loadCategories();
    await AppModule.loadCategories(true);
  }

  /**
   * @todo Show current company name
   */
  get title() {
    return "Inscriçoes Xtreme";
  }
}
