




























import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class MainDrawer extends Vue {
  @Prop({ type: Array, default: [] }) readonly items!: string[];
}
