import store from "@/store";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import CategoryModel from "@bit/planetadeleste.shopaholic.models.category";

type setCategoryOptions = {
  data: CategoryModel[];
  events: boolean;
};

@Module({ dynamic: true, store, name: "app" })
class App extends VuexModule {
  private _products_categories: CategoryModel[] = [];
  private _events_categories: CategoryModel[] = [];

  @Mutation
  private SET_CATEGORIES(payload: setCategoryOptions) {
    if (payload.events) {
      this._events_categories = payload.data;
    } else {
      this._products_categories = payload.data;
    }
  }

  @Action
  async loadCategories(byEvents: boolean = false) {
    const category = new CategoryModel();
    category
      .preventLoading()
      .byTree()
      .byActive();

    if (byEvents) {
      category.byEvents();
    } else {
      category.byProducts();
    }

    const response = await category.list().get();

    if (response) {
      this.SET_CATEGORIES({
        data: response.data || response,
        events: byEvents
      });
    }
  }

  get shopCategories() {
    return this._products_categories;
  }

  get eventsCategories() {
    return this._events_categories;
  }
}

export const AppModule = getModule(App);
