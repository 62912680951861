









import { Component, Vue } from "vue-property-decorator";
import { FlashModule } from "@/store/flash";

@Component
export default class FlashMessage extends Vue {
  get snack(): boolean {
    return FlashModule.getSnack && !this.$_.isEmpty(this.message);
  }

  get color(): string {
    return FlashModule.getSnackColor;
  }

  get message(): string {
    return FlashModule.getSnackText;
  }

  closeFlash(): void {
    FlashModule.closeFlash();
  }
}
