import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { i18n, loadLanguageAsync } from "@/plugins/i18n";

// import "components/types/vue-api-query";
import _ from "lodash";
import "@/plugins";

// Set API Model
import { Model } from "vue-api-query";
import BaseApiModel from "@bit/planetadeleste.shopaholic.models.base-api-model";
import { Route } from "vue-router";
import { AuthModule } from "./store/auth";
import { LoadingModule } from "./store/loading";
import { FlashModule } from "./store/flash";
import { VueSvgIcon } from "@yzfe/vue-svgicon";

Model.$http = Vue.axios;
BaseApiModel.$authModule = AuthModule;
BaseApiModel.$loadingModule = LoadingModule;
BaseApiModel.$flashModule = FlashModule;

Vue.config.productionTip = false;
const sUser = localStorage.getItem("user");
const user = _.isString(sUser) ? JSON.parse(sUser) : null;
if (user) {
  Vue.prototype.$user.set(user);
}

router.beforeEach(async (to: Route, from: Route, next: Function) => {
  const lang = to.params.lang || i18n.locale;
  if (lang) {
    await loadLanguageAsync(lang).then(() => next());
  } else {
    next();
  }
});

Vue.component("icon", VueSvgIcon);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
