






import { Vue, Component } from "vue-property-decorator";
import { ShoppingCartIcon } from "vue-feather-icons";

@Component({
  components: {
    ShoppingCartIcon
  }
})
export default class MiniCart extends Vue {}
