import { RouteConfig, RedirectOption, NavigationGuard } from "vue-router";
import {
  PathToRegexpOptions,
  Component,
  RoutePropsFunction
} from "vue-router/types/router";
import _ from "lodash";

// Import routes
import routerHome from "@/modules/home/router";
import routerShop from "@/modules/shop/router";
import routerEvents from "@/modules/events/router";

interface IRouteConfig {
  page?: string;
  path: string;
  name?: string;
  children?: RouteConfig[];
  redirect?: RedirectOption;
  alias?: string | string[];
  meta?: object;
  beforeEnter?: NavigationGuard;
  caseSensitive?: boolean;
  pathToRegexpOptions?: PathToRegexpOptions;
  component?: Component;
  props?: boolean | object | RoutePropsFunction;
}

const baseRoutes: IRouteConfig[] = [];

const errorRoutes: IRouteConfig[] = [
  {
    path: "*",
    page: "404",
    name: "error404",
    meta: {
      title: "Error 404",
      icon: "lni-warning",
      drawer: false,
      main: true
    }
  }
];

const mapRoute = (route: IRouteConfig, sModuleName?: string) => {
  const hasChild = _.isArray(route.children);
  // const isMain = _.get(route, "meta.main", false);
  const sModule = sModuleName || _.get(route, "meta.module");
  const sComponentPath = `modules/${sModule}/views`;
  const sView = _.chain(route.page)
    .camelCase()
    .upperFirst()
    .value();

  if (hasChild) {
    route.children = _.map(route.children, r => mapRoute(r, sModule));
  }

  route.component = () => import(`@/${sComponentPath}/${sView}.vue`);

  return route;
};

const routes = _.chain(baseRoutes)
  .concat(routerHome, routerShop, routerEvents, errorRoutes)
  .map(r => mapRoute(r))
  .value();

export default routes;
