


















































import { Vue, Component } from "vue-property-decorator";
import MainDrawer from "@/layout/components/Drawer.vue";
import MiniCart from "@/components/cart/MiniCart.vue";

@Component({
  components: {
    MainDrawer,
    MiniCart
  }
})
export default class AppBar extends Vue {
  drawer = false;
  items: string[] = ["home", "events", "shop"];

  get maxWidth() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
      case "sm":
      case "md":
        return "100%";
      case "lg":
        return "1185px";
      case "xl":
        return "1785px";
    }
  }
}
