export default [
  {
    path: "/",
    page: "index",
    name: "home",
    meta: {
      module: "home",
      title: "home",
      drawer: true,
      main: true
    }
  }
];
