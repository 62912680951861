export default [
  {
    path: "/events",
    page: "index",
    name: "events",
    meta: {
      module: "events",
      title: "events",
      drawer: true,
      main: true
    },
    children: [
      {
        path: ":slug",
        page: "category",
        name: "events_category",
        meta: {
          module: "events",
          title: "events.category"
        }
      }
    ]
  }
];
