import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";

@Module({
  name: "layout",
  dynamic: true,
  namespaced: true,
  store: store
})
class Layout extends VuexModule {
  public layout = "init";
  public reload = false;

  @Mutation
  private SET_LAYOUT(name: string) {
    this.layout = name;
  }

  @Mutation
  private SET_RELOAD(reload: boolean) {
    this.reload = reload;
  }

  @Action
  setLayout(layout: string) {
    this.SET_LAYOUT(layout);
  }

  @Action
  setReload(reload: boolean) {
    this.SET_RELOAD(reload);
  }

  @Action
  setReloadOn() {
    this.SET_RELOAD(true);
  }

  @Action
  setReloadOff() {
    this.SET_RELOAD(false);
  }

  get getReload(): boolean {
    return this.reload;
  }

  get getLayout(): string {
    return this.layout;
  }
}

export const LayoutModule = getModule(Layout);
