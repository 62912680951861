import {
  Module,
  VuexModule,
  Action,
  getModule,
  Mutation
} from "vuex-module-decorators";
import store from "@/store";
import UserModel from "@bit/planetadeleste.shopaholic.models.user";
import _ from "lodash";
import axios from "axios";

export interface IAuthStatus {
  loggingIn: boolean;
  loggedIn: boolean;
}

const sUser = localStorage.getItem("user");
const obUser = sUser ? JSON.parse(sUser) : {};
const initStatus: IAuthStatus = {
  loggingIn: false,
  loggedIn: _.has(obUser, "id")
};

@Module({
  name: "authentication",
  dynamic: true,
  store: store,
  namespaced: true
})
class Auth extends VuexModule {
  user: UserModel = obUser;
  status: IAuthStatus = initStatus;
  redirect = "";
  _csrfToken = "";

  @Mutation
  private SET_LOGGING(status: boolean) {
    this.status.loggingIn = status;
  }

  @Mutation
  private SET_LOGGEDIN(status: boolean) {
    this.status.loggedIn = status;
  }

  @Mutation
  private SET_USER(user: UserModel) {
    this.user = user;
  }

  @Mutation
  private SET_REDIRECT(path: string) {
    this.redirect = path;
  }

  @Mutation
  private SET_CSRF(token: string) {
    this._csrfToken = token;
  }

  @Action
  loginRequest(user: UserModel) {
    this.SET_LOGGING(true);
    this.SET_USER(user);
  }

  @Action
  loginSuccess(user: UserModel) {
    this.SET_LOGGING(false);
    this.SET_LOGGEDIN(true);
    this.SET_USER(user);
  }

  @Action
  loginFailure() {
    this.logout();
  }

  @Action
  logout() {
    this.SET_LOGGING(false);
    this.SET_LOGGEDIN(false);
    this.SET_USER(new UserModel());
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
  }

  @Action
  redirectTo(path: string) {
    this.SET_REDIRECT(path);
  }

  @Action
  async loadCsrf() {
    const response = await axios.get("/auth/csrf");
    if (response.data) {
      this.SET_CSRF(_.get(response.data, "data.token"));
    }
  }

  get isLogged(): boolean {
    return this.status.loggedIn;
  }

  get getUser(): UserModel {
    return this.user instanceof UserModel
      ? this.user
      : new UserModel(this.user);
  }

  get getRedirectTo(): string | null {
    return this.redirect.length ? this.redirect : null;
  }

  get csrfToken() {
    return this._csrfToken;
  }
}

export const AuthModule = getModule(Auth);
